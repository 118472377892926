<template>
  <b-card
    class="bg-gray-100 card--profile-search"
    footer-class="px-2"
  >
    <h5 class="d-flex">
      <span class="me-1">
        <font-awesome-icon icon="building" class="fa-fw" />
      </span>
      <span>{{companyName}}</span>
    </h5>
    <div class="ms-4 ps-2">
      <p>
        <strong>{{taxCodeLabel}}</strong>
        {{taxCode}}
      </p>
    </div>
    <template #footer>
      <div class="d-md-flex justify-content-md-end">
        <b-button
          variant="info"
          size="sm"
          class="d-block d-md-inline-block"
          @click="routeTo()"
        >
          {{itemButtonLabel}}
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>

import { isNotEmpty } from '@/utils/validators';

export default {
  name: 'ProfileSearchDataSearchItem',
  components: { },
  props: {
    data: Object,
    context: String,
  },
  computed: {
    companyName() {
      // console.log('? item?', this.data);
      if (isNotEmpty(this.data?.companyNameUsed)) return this.data.companyNameUsed;
      return isNotEmpty(this.data?.info?.companyName) ? this.data.info.companyName : '';
    },
    taxCodeLabel() {
      return 'P.IVA: ';
    },
    taxCode() {
      if (isNotEmpty(this.data?.taxCodeUsed)) return this.data.taxCodeUsed;
      if (isNotEmpty(this.data?.vatNumberUsed)) return this.data.vatNumberUsed;
      return isNotEmpty(this.data?.info?.taxCode) ? this.data.info.taxCode : '';
    },
    itemButtonLabel() {
      return 'Visualizza';
    },
    currentContext() {
      return this.$props.context;
    },
  },
  methods: {
    routeTo() {
      const baseUrl = this.currentContext === 'click' ? 'mf-solvency' : 'company-search';
      this.$router.push(`/${baseUrl}/company/${this.taxCode}`);
    },
  },
};
</script>

<style scoped>

</style>
